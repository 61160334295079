<template>
  <section class="invoice-preview-wrapper">
    <b-row
      v-if="quotationData"
      class="invoice-preview quotation-status"
      :class="{
        'quotation-confirmed': $route.params.status == '1',
        'quotation-toPaid': $route.params.status == '2',
        'quotation-await': $route.params.status == '3',
      }"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <logo />
                </div>
                <p class="card-text mb-25">
                  {{ quotationData.transmitter.office }}
                </p>
                <p class="card-text mb-25">
                  {{ quotationData.transmitter.address }}
                </p>
                <p class="card-text mb-0">
                  {{ quotationData.transmitter.phone }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ $t('common.quotation') | capitalize }}
                  <span class="invoice-number">#{{ quotationData.id }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">{{ $t('quotation.date_issued') | capitalize }}:</p>
                  <p class="invoice-date">
                    {{ quotationData.issuedDate | dateFormat }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">{{ $t('quotation.due_date') | capitalize }}:</p>
                  <p class="invoice-date">
                    {{ quotationData.dueDate }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body v-if="quotationData.client" class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <h6 class="mb-2">{{ $t('invoice.to') | capitalize }}:</h6>
                <h6 class="mb-25">
                  {{ quotationData.client.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ quotationData.client.company }}
                </p>
                <p class="card-text mb-25">{{ quotationData.client.address }}, {{ quotationData.client.country }}</p>
                <p class="card-text mb-25">
                  {{ quotationData.client.contact }}
                </p>
                <p class="card-text mb-0">
                  {{ quotationData.client.companyEmail }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                <div>
                  <h6 class="mb-2">{{ $t('invoice.payment_details') | capitalize }}:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">{{ $t('invoice.total_due') }}:</td>
                        <td>
                          <span class="font-weight-bold">{{ paymentDetails.totalDue }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">{{ $t('order.bank_name') | capitalize }}:</td>
                        <td>{{ paymentDetails.bankName }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">{{ $t('common.country') | capitalize }}:</td>
                        <td>{{ paymentDetails.country }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">{{ $t('quotation.payment.iban') }}:</td>
                        <td>{{ paymentDetails.iban }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">{{ $t('quotation.payment.swift') }}:</td>
                        <td>{{ paymentDetails.swiftCode }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite responsive :items="serviceData.items" :fields="['title', 'HT', 'TVA', 'TTC', 'qty', 'totalHT']">
            <template #cell(title)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.title }}
              </b-card-text>
            </template>
            <template #cell(HT)="data">
              <b-card-text class="font-weight-bold mb-25"> {{ data.item.HT }}{{ $store.state.appConfig.currency }} </b-card-text>
            </template>
            <template #cell(TVA)="data">
              <b-card-text class="font-weight-bold mb-25"> {{ data.item.TVA }}% </b-card-text>
            </template>
            <template #cell(TTC)="data">
              <b-card-text class="font-weight-bold mb-25"> {{ data.item.TTC }}{{ $store.state.appConfig.currency }} </b-card-text>
            </template>
            <template #cell(qty)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.qty }}
              </b-card-text>
            </template>
            <template #cell(totalHT)="data">
              <b-card-text class="font-weight-bold mb-25"> {{ data.item.totalHT }}{{ $store.state.appConfig.currency }} </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                <b-card-text class="mb-0">
                  <span class="font-weight-bold"> {{ $t('quotation.sales_person') }} :</span>
                  <span class="ml-75">{{ serviceData.salesperson }}</span>
                </b-card-text>
              </b-col>

              <!-- Col: Calc total -->
              <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                <quotation-bill :services="serviceData.items" />
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />
          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('quotation.note') }}: </span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!--Badge validated-->
          <div v-if="$route.params.status == '1'" class="mb-3">
            <!-- Button: Payment authorisation -->
            <b-badge class="d-block mb-1 p-1" variant="light-success">
              {{ $t('quotation.payment.authorisation_validated') }}
            </b-badge>
            <b-card-text class="text-center">
              {{ serviceData.payment.dateIssued | dateFormat }} {{ $t('quotation.payment.by_credit_card') }}
            </b-card-text>
          </div>
          <!--Badge claim-->
          <div v-if="$route.params.status == '2'" class="text-center">
            <b-badge class="d-block mb-1 p-1" variant="light-danger">
              {{ $t('quotation.status.claim_posted') }} {{ serviceData.payment.dateClaim | dateFormat }}
            </b-badge>
          </div>
          <!--Badge be paid-->
          <div v-if="$route.params.status == '3'" class="text-center mb-3">
            <b-badge class="d-block mb-1 p-1" variant="light-warning">
              {{ $t('quotation.status.be_paid_by_customer') }}
            </b-badge>
          </div>

          <!-- Button: Handle this claim -->
          <b-button v-if="$route.params.status == '2'" v-ripple.400 variant="danger" class="mb-3" :to="{ name: 'chat', params: { channel: 'claim' } }" block>
            <font-awesome-icon class="mr-50" icon="cogs" />
            <span class="align-middle">{{ $t('quotation.status.claim_handle') }}</span>
          </b-button>

          <!-- Button: Edit quotation -->
          <div v-else>
            <b-button
              v-if="checkOrganizationAccess('TRIP_ACCOUNTING_EDIT', tripData.organizationId)"
              v-ripple.400
              variant="warning"
              class="mb-75"
              :to="{ name: 'quotation-custom', params: { services: serviceData } }"
              block
            >
              <font-awesome-icon class="mr-50" icon="edit" />
              <span class="align-middle"> {{ $t('action.edit') }} </span>
            </b-button>
            <!-- Button: Cancel quotation -->
            <b-button v-ripple.400 variant="danger" class="mb-75" block @click="modalCancelQuotation()">
              <font-awesome-icon icon="ban" class="mr-50" />
              {{ $t('action.cancel') }}
            </b-button>
          </div>
          <!-- Button: Download -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-primary" class="mb-75" block>
            <font-awesome-icon class="mr-50" icon="download" />
            {{ $t('action.download') }}
          </b-button>

          <!-- Button: Print -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-primary" class="mb-75" block @click="printQuotation">
            <font-awesome-icon class="mr-50" icon="print" />
            {{ $t('action.print') }}
          </b-button>
        </b-card>
        <!-- Cancellation policy desc  -->
        <div class="d-flex justify-content-center p-1 bg-light-primary rounded">
          <div>
            <h5 class="text-center py-1">
              {{ $tc('cancellation_policy.title', 1) }}
            </h5>
            <ul class="text-body">
              <li v-for="(item, index) in cancellationPolicy.desc" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import Logo from '@core/layouts/components/Logo.vue'

import QuotationBill from '@/views/quotation/component/QuotationBill.vue'
import AccessControl from '@/helpers/accessControl'

export default {
  name: 'QuotationDetail',

  components: {
    Logo,
    QuotationBill,
  },
  mixins: [AccessControl],
  data() {
    return {
      quotationData: {
        id: 4987,
        issuedDate: '13 Dec 2019',
        client: {
          address: '7777 Mendez Plains',
          company: 'Hall-Robbins PLC',
          companyEmail: 'don85@johnson.com',
          country: 'USA',
          contact: '(616) 865-4180',
          name: 'Jordan Stevenson',
        },
        transmitter: {
          office: 'Office 149, 450 South Brand Brooklyn',
          address: 'San Diego County, CA 91905, USA',
          phone: '+1 (123) 456 7891',
        },
        service: 'Software Development',
        total: 3428,
        avatar: '',
        invoiceStatus: 'Paid',
        dueDate: '23 Apr 2019',
      },
      cancellationPolicy: {
        label: 'Modèle 1',
        desc: ['Si le client annule 24 heures avant le voyage alors 50% de pénalité', 'Si le client reporte 24 heures avant le voyage alors pas de pénalité'],
      },
      serviceData: {
        id: 4987,
        issuedDate: '13 Dec 2019',
        salesperson: 'Brian',
        client: {
          address: '7777 Mendez Plains',
          company: 'Hall-Robbins PLC',
          companyEmail: 'don85@johnson.com',
          country: 'USA',
          contact: '(616) 865-4180',
          name: 'Jordan Stevenson',
        },
        service: 'Aircraft desinfection',
        serviceDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        total: 3428,
        avatar: '',
        invoiceStatus: 'Paid',
        dueDate: '23 Apr 2019',
        items: [
          {
            title: 'Aircraft desinfection',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            HT: 250,
            TTC: 275,
            qty: 1,
            TVA: 10,
            discount: 0,
            totalHT: 250,
            date: '',
            time: '',
          },
          {
            title: 'Dishwashing',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            HT: 100,
            TTC: 240,
            qty: 2,
            TVA: 20,
            discount: 0,
            totalHT: 200,
            date: '',
            time: '',
          },
        ],
        note: 'Note',
        paymentMethod: 'Bank Account',
        payment: {
          dateIssued: '2021-07-08',
          dateClaim: '2021-07-08',
        },
      },
      paymentDetails: {
        totalDue: '12,110.55',
        bankName: 'American Bank',
        country: 'United States',
        iban: 'ETD95476213874685',
        swiftCode: 'BR91905',
      },
    }
  },
  computed: {
    tripData() {
      const tripId = Number(this.$route.params.trip_id)
      return this.$store.getters['trip/currentTrip'](tripId)
    },
  },
  methods: {
    printQuotation() {
      window.print()
    },
    modalCancelQuotation() {
      this.$bvModal
        .msgBoxConfirm(this.$t('quotation.modal.cancel'), {
          title: this.$t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.yes'),
          cancelTitle: this.$t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$router.push({ name: 'trip-details', params: { trip_id: this.$route.params.trip_id } })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss">
.quotation-status {
  position: relative;
  overflow: hidden;
  &.quotation-confirmed {
    &:after {
      content: 'CONFIRMED';
      color: #28c76f;
    }
  }
  &.quotation-toPaid {
    &:after {
      content: 'CLAIM';
      color: #ea5455;
    }
  }
  &.quotation-await {
    &:after {
      content: 'awaiting payment';
      color: #ff8b1d;
    }
  }
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 30%;
    height: 90%;
    width: 90%;
    text-align: center;
    font-weight: 900;
    font-size: 100px;
    opacity: 0.3;
    transform: rotate(335deg);
  }
}

.invoice-actions {
  z-index: 2;
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
